import './index.scss'
import 'animate.css';
import { Image } from '@chakra-ui/react'
import { TypeAnimation } from 'react-type-animation';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import { Parallax } from 'react-parallax';

const Timeline = () => {

    function handleGPlay() {
        window.open('https://play.google.com/store/apps/developer?id=The+Joloto+Project', '_blank');
    }

    function handleAppStore() {
        alert('store  is deactivated')
    }

    var settings = {
        autoplay: true,
        dots: true,
        infinite: true,
        speed: 500,
        autoplaySpeed: 10000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className={'home-container'}>
            < Parallax blur={0} bgImage={require('../../assets/images/wallpaper.png')} bgImageAlt="wallpaper" strength={800} style={{
                width: "100%",
                height: '800px',
            }}
                bgImageStyle={{ height: '800px', width: '100%' }}
            >
                <div className={'content'}>
                    <div className={'type-animation'}>
                        <TypeAnimation
                            sequence={[
                                '@thejolotoproject',
                                1000,
                            ]}
                            wrapper="span"
                            speed={40}
                            style={{ fontSize: '2.5em', display: 'inline-block', zIndex: 1, textShadow: "4px 4px 20px black" }}
                            repeat={Infinity}
                        />
                    </div>

                    <div id={'slick'}>
                        {/* <div id={styles.slick} style={{ width: '100%', margin: 24, zIndex: 1 }}> */}
                        <Slider {...settings}>
                            <div className={'slick-text'} >
                                <h3>Pentestkit Mobile is a comprehensive tool good for Bug Bounty Hunters, Penetration Testers, Security Researchers, Students and who wants to learn and practice cyber security holes and vulnerability.</h3>
                            </div>
                            <div className={'slick-text'}>
                                <h3>WeGuitar is an Application that will help you to find and search for specific OPM song containing chords and lyrics. This app is good for Guitarists, Sessionists, Vocalists, Jammer and all the Musicians who loves MUSIC.</h3>
                            </div>
                        </Slider>
                    </div>
                    <div className={'actions'}>
                        <button onClick={handleGPlay}>
                            <Image
                                src={require('../../assets/images/gplay.png')}
                                height={74}
                                width={250}
                                alt="Google Play"
                            />
                        </button>
                        <button onClick={handleAppStore}>
                            <Image
                                src={require('../../assets/images/appstore.png')}
                                height={74}
                                width={250}
                                alt="Your Name"
                            />
                        </button>
                    </div>
                </div>
            </Parallax>
            <div className={'floating-image'}>
                <Image
                    src={require('../../assets/images/phones.png')}
                    height={800}
                    width={800}
                    alt="Your Name"
                />
            </div>
        </div >
    )
}

export default Timeline